var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "btn-toolbar" }, [
      _c("div", { staticClass: "btn-group btn-group-sm ml-auto" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.editModel(_vm.createUrl)
              }
            }
          },
          [_vm._v("CREAR GRUPO")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "alert-snackbar-container" },
      _vm._l(_vm.alerts, function(alert) {
        return _c(
          "div",
          {
            class:
              "alert alert-dismissible alert-snackbar fade alert-" +
              alert.type +
              (alert.show ? " show" : ""),
            attrs: { role: "alert" }
          },
          [
            _vm._v("\n            " + _vm._s(alert.message) + "\n            "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button", "aria-label": "Close" },
                on: {
                  click: function($event) {
                    return _vm.closeAlert(alert)
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade form-modal",
        attrs: {
          id: "formModal",
          "data-backdrop": "static",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-xl", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              !_vm.formObject.route
                ? _c("div", [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "div",
                        {
                          class:
                            "alert alert-danger fade" +
                            (_vm.formErrors.message ? " show" : "")
                        },
                        [_vm._v(_vm._s(_vm.formErrors.message))]
                      ),
                      _vm._v(" "),
                      _vm.formLoading
                        ? _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-center p-4"
                            },
                            [_c("div", { staticClass: "spinner-border" })]
                          )
                        : _vm._e()
                    ])
                  ])
                : _c(
                    "form",
                    {
                      ref: "form",
                      attrs: {
                        action: _vm.formObject.route,
                        method: "post",
                        "accept-charset": "UTF-8",
                        files: "true"
                      },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.updateModel($event.target)
                        }
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formObject.method,
                            expression: "formObject.method"
                          }
                        ],
                        attrs: { type: "hidden", name: "_method" },
                        domProps: { value: _vm.formObject.method },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formObject,
                              "method",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-header" }, [
                        _c("h5", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.formObject.title))
                        ]),
                        _vm._v(" "),
                        _vm._m(1)
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-body" }, [
                        _c(
                          "div",
                          {
                            class:
                              "alert alert-danger fade" +
                              (_vm.formErrors.message ? " show" : "")
                          },
                          [_vm._v(_vm._s(_vm.formErrors.message))]
                        ),
                        _vm._v(" "),
                        _vm._m(2),
                        _vm._v(" "),
                        _c("div", { staticClass: "tab-content" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane active",
                              attrs: { id: "tabInfo" }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "title" } }, [
                                        _vm._v("Título")
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.formObject.group.title,
                                            expression: "formObject.group.title"
                                          }
                                        ],
                                        class:
                                          "form-control" +
                                          (_vm.formErrors.errors.title
                                            ? " is-invalid"
                                            : ""),
                                        attrs: {
                                          id: "title",
                                          name: "title",
                                          type: "text",
                                          autofocus: "",
                                          required: ""
                                        },
                                        domProps: {
                                          value: _vm.formObject.group.title
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.formObject.group,
                                              "title",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.formErrors.errors.title,
                                        function(error) {
                                          return _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(error) +
                                                  "\n                                            "
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "year" } }, [
                                        _vm._v("Año")
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.formObject.group.year,
                                            expression: "formObject.group.year"
                                          }
                                        ],
                                        class:
                                          "form-control" +
                                          (_vm.formErrors.errors.year
                                            ? " is-invalid"
                                            : ""),
                                        attrs: {
                                          id: "year",
                                          name: "year",
                                          type: "text"
                                        },
                                        domProps: {
                                          value: _vm.formObject.group.year
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.formObject.group,
                                              "year",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.formErrors.errors.year,
                                        function(error) {
                                          return _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(error) +
                                                  "\n                                            "
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "current" } },
                                        [_vm._v("Grupo actual")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.formObject.group.current,
                                              expression:
                                                "formObject.group.current"
                                            }
                                          ],
                                          class:
                                            "custom-select" +
                                            (_vm.formErrors.errors.current
                                              ? " is-invalid"
                                              : ""),
                                          attrs: {
                                            name: "current",
                                            id: "current",
                                            required: ""
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.formObject.group,
                                                "current",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "0" } },
                                            [_vm._v("No")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1" } },
                                            [_vm._v("Sí")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.formErrors.errors.current,
                                        function(error) {
                                          return _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(error) +
                                                  "\n                                            "
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 col-md-6" },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "group_type_id" } },
                                      [_vm._v("Tipo de grupo")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.formObject.group
                                                .group_type_id,
                                            expression:
                                              "formObject.group.group_type_id"
                                          }
                                        ],
                                        class:
                                          "custom-select" +
                                          (_vm.formErrors.errors.group_type_id
                                            ? " is-invalid"
                                            : ""),
                                        attrs: {
                                          name: "group_type_id",
                                          id: "group_type_id",
                                          required: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.formObject.group,
                                              "group_type_id",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      _vm._l(
                                        _vm.formObject.groupTypes,
                                        function(groupType) {
                                          return _c(
                                            "option",
                                            {
                                              domProps: { value: groupType.id }
                                            },
                                            [_vm._v(_vm._s(groupType.name))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.formErrors.errors.group_type_id,
                                      function(error) {
                                        return _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(error) +
                                                "\n                                        "
                                            )
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "published" } },
                                        [_vm._v("Publicado")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.formObject.group.published,
                                              expression:
                                                "formObject.group.published"
                                            }
                                          ],
                                          class:
                                            "custom-select" +
                                            (_vm.formErrors.errors.published
                                              ? " is-invalid"
                                              : ""),
                                          attrs: {
                                            name: "published",
                                            id: "published",
                                            required: ""
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.formObject.group,
                                                "published",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "1" } },
                                            [_vm._v("Sí")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "0" } },
                                            [_vm._v("No")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.formErrors.errors.published,
                                        function(error) {
                                          return _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(error) +
                                                  "\n                                            "
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "schedule" } },
                                      [_vm._v("Horarios")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.formObject.group.schedule,
                                          expression:
                                            "formObject.group.schedule"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "schedule",
                                        name: "schedule",
                                        type: "text"
                                      },
                                      domProps: {
                                        value: _vm.formObject.group.schedule
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.formObject.group,
                                            "schedule",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { attrs: { for: "place" } }, [
                                      _vm._v("Lugar")
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.formObject.group.place,
                                          expression: "formObject.group.place"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "place",
                                        name: "place",
                                        type: "text"
                                      },
                                      domProps: {
                                        value: _vm.formObject.group.place
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.formObject.group,
                                            "place",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "div",
                                        { class: _vm.mainFilesIsInvalid },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { for: "mainFiles[]" } },
                                            [
                                              _vm._v(
                                                "Textos principales (los que se leyeron)"
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("multiselect", {
                                            attrs: {
                                              "internal-search": false,
                                              options:
                                                _vm.filteredMainFilesSorted,
                                              multiple: true,
                                              "close-on-select": false,
                                              "hide-selected": true,
                                              placeholder: "",
                                              selectLabel: "",
                                              "track-by": "id",
                                              label: "fullTitle"
                                            },
                                            on: {
                                              "search-change":
                                                _vm.mainFilesSearch,
                                              select: _vm.removeSecondaryItem,
                                              remove: _vm.addSecondaryItem
                                            },
                                            model: {
                                              value:
                                                _vm.formObject
                                                  .selectedMainFiles,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formObject,
                                                  "selectedMainFiles",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formObject.selectedMainFiles"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.formObject.selectedMainFiles,
                                            function(file) {
                                              return _c("input", {
                                                attrs: {
                                                  name: "mainFiles[]",
                                                  type: "hidden"
                                                },
                                                domProps: { value: file.id }
                                              })
                                            }
                                          )
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.getArrayErrors(
                                          _vm.formErrors.errors,
                                          "mainFiles"
                                        ),
                                        function(error) {
                                          return _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(error) +
                                                  "\n                                            "
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "div",
                                        { class: _vm.secondaryFilesIsInvalid },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: { for: "secondaryFiles[]" }
                                            },
                                            [_vm._v("Textos secundarios")]
                                          ),
                                          _vm._v(" "),
                                          _c("multiselect", {
                                            attrs: {
                                              "internal-search": false,
                                              options:
                                                _vm.filteredSecondaryFilesSorted,
                                              multiple: true,
                                              "close-on-select": false,
                                              "hide-selected": true,
                                              placeholder: "",
                                              selectLabel: "",
                                              "track-by": "id",
                                              label: "fullTitle"
                                            },
                                            on: {
                                              "search-change":
                                                _vm.secondaryFilesSearch,
                                              select: _vm.removeMainItem,
                                              remove: _vm.addMainItem
                                            },
                                            model: {
                                              value:
                                                _vm.formObject
                                                  .selectedSecondaryFiles,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formObject,
                                                  "selectedSecondaryFiles",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formObject.selectedSecondaryFiles"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.formObject
                                              .selectedSecondaryFiles,
                                            function(file) {
                                              return _c("input", {
                                                attrs: {
                                                  name: "secondaryFiles[]",
                                                  type: "hidden"
                                                },
                                                domProps: { value: file.id }
                                              })
                                            }
                                          )
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.getArrayErrors(
                                          _vm.formErrors.errors,
                                          "secondaryFiles"
                                        ),
                                        function(error) {
                                          return _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(error) +
                                                  "\n                                            "
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-12 col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          class:
                                            "d-block" +
                                            (_vm.formErrors.errors.image
                                              ? " is-invalid"
                                              : ""),
                                          attrs: { for: "image" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                Imagen\n                                                "
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [_vm._v("(máximo 600kB)")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.formErrors.errors.image,
                                        function(error) {
                                          return _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(error) +
                                                  "\n                                            "
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class:
                                            "fileinput fileinput-" +
                                            (_vm.formObject.group.image
                                              ? "exists"
                                              : "new"),
                                          attrs: {
                                            "data-provides": "fileinput",
                                            "data-error-key": "image",
                                            "data-max-size": "0.6"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "fileinput-preview img-thumbnail",
                                              staticStyle: {
                                                width: "200px",
                                                height: "150px"
                                              },
                                              attrs: {
                                                "data-trigger": "fileinput"
                                              }
                                            },
                                            [
                                              _vm.formObject.group.image
                                                ? _c("img", {
                                                    staticClass: "img-fluid",
                                                    attrs: {
                                                      src:
                                                        _vm.formObject.group
                                                          .imageUrl,
                                                      alt: "Imagen del grupo"
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._m(3)
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane",
                              attrs: { id: "tabDescription" }
                            },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formObject.group.description,
                                    expression: "formObject.group.description"
                                  }
                                ],
                                staticClass: "form-control markdown",
                                attrs: {
                                  id: "description",
                                  name: "description",
                                  rows: "10"
                                },
                                domProps: {
                                  value: _vm.formObject.group.description
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formObject.group,
                                      "description",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane",
                              attrs: { id: "tabNotes" }
                            },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formObject.group.notes,
                                    expression: "formObject.group.notes"
                                  }
                                ],
                                staticClass: "form-control markdown",
                                attrs: {
                                  id: "notes",
                                  name: "notes",
                                  rows: "10"
                                },
                                domProps: { value: _vm.formObject.group.notes },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formObject.group,
                                      "notes",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane",
                              attrs: { id: "tabTasks" }
                            },
                            [
                              _c("div", { staticClass: "btn-toolbar mb-3" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-group btn-group-sm ml-auto"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary",
                                        attrs: { type: "button" },
                                        on: { click: _vm.addTask }
                                      },
                                      [
                                        _c("i", { staticClass: "fas fa-plus" }),
                                        _vm._v(
                                          "\n                                            Agregar tarea\n                                        "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "table-responsive" }, [
                                _c(
                                  "table",
                                  { staticClass: "table table-sm table-hover" },
                                  [
                                    _vm._m(4),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.formObject.group.tasks,
                                        function(task, index) {
                                          return _c("tr", [
                                            _c("td", [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: task.description,
                                                    expression:
                                                      "task.description"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  name:
                                                    "tasks[" +
                                                    index +
                                                    "][description]",
                                                  rows: "1",
                                                  required: ""
                                                },
                                                domProps: {
                                                  value: task.description
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      task,
                                                      "description",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: task.id,
                                                    expression: "task.id"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    "tasks[" + index + "][id]",
                                                  type: "hidden"
                                                },
                                                domProps: { value: task.id },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      task,
                                                      "id",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("flat-pickr", {
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    config: _vm.flatPickrCfg,
                                                    name:
                                                      "tasks[" +
                                                      index +
                                                      "][dateDmy]"
                                                  },
                                                  model: {
                                                    value: task.dateDmy,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        task,
                                                        "dateDmy",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "task.dateDmy"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "table-btns" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-danger",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeTask(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-trash-alt"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.getArrayErrors(
                                  _vm.formErrors.errors,
                                  "tasks"
                                ),
                                function(error) {
                                  return _c(
                                    "div",
                                    { staticClass: "invalid-feedback d-block" },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(error) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-footer" }, [
                        _vm.formObject.group.id
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-outline-danger mr-auto",
                                attrs: {
                                  disabled: _vm.formDeleting,
                                  "data-toggle": "modal",
                                  "data-target": "#confirmationModal",
                                  type: "button"
                                }
                              },
                              [
                                !_vm.formDeleting
                                  ? _c("span", [_vm._v("ELIMINAR")])
                                  : _c("span", {
                                      staticClass:
                                        "spinner-border spinner-border-sm"
                                    })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-secondary",
                            attrs: { "data-dismiss": "modal", type: "button" }
                          },
                          [_vm._v("Cancelar")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary",
                            attrs: { disabled: _vm.formSending, type: "submit" }
                          },
                          [
                            !_vm.formSending
                              ? _c("span", [_vm._v("GUARDAR")])
                              : _c("span", {
                                  staticClass:
                                    "spinner-border spinner-border-sm"
                                })
                          ]
                        )
                      ])
                    ]
                  )
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "confirmationModal",
          "data-backdrop": "static",
          tabindex: "-1",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(5),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" }
                  },
                  [_vm._v("Cancelar")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-danger",
                    attrs: { "data-dismiss": "modal", type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.updateModel(_vm.$refs.form, true)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        Aceptar\n                    "
                    )
                  ]
                )
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c("div", { staticClass: "spinner-border" })
        ])
      : _c(
          "div",
          { staticClass: "pt-4" },
          [
            _c("v-client-table", {
              attrs: {
                data: _vm.fetchedData.items,
                columns: _vm.fetchedData.columns,
                options: _vm.fetchedData.options
              },
              scopedSlots: _vm._u([
                {
                  key: "notes",
                  fn: function(props) {
                    return _c("div", {}, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: props.row.notes,
                              expression: "props.row.notes"
                            }
                          ]
                        },
                        [_c("i", { staticClass: "fas fa-check text-success" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !props.row.notes,
                              expression: "! props.row.notes"
                            }
                          ]
                        },
                        [_c("i", { staticClass: "fas fa-times text-danger" })]
                      )
                    ])
                  }
                },
                {
                  key: "image",
                  fn: function(props) {
                    return _c("div", {}, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: props.row.image,
                              expression: "props.row.image"
                            }
                          ]
                        },
                        [_c("i", { staticClass: "fas fa-check text-success" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !props.row.image,
                              expression: "! props.row.image"
                            }
                          ]
                        },
                        [_c("i", { staticClass: "fas fa-times text-danger" })]
                      )
                    ])
                  }
                },
                {
                  key: "current",
                  fn: function(props) {
                    return _c("div", {}, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: props.row.current,
                              expression: "props.row.current"
                            }
                          ]
                        },
                        [_c("i", { staticClass: "fas fa-check text-success" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !props.row.current,
                              expression: "! props.row.current"
                            }
                          ]
                        },
                        [_c("i", { staticClass: "fas fa-times text-danger" })]
                      )
                    ])
                  }
                },
                {
                  key: "published",
                  fn: function(props) {
                    return _c("div", {}, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: props.row.published,
                              expression: "props.row.published"
                            }
                          ]
                        },
                        [_c("i", { staticClass: "fas fa-check text-success" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !props.row.published,
                              expression: "! props.row.published"
                            }
                          ]
                        },
                        [_c("i", { staticClass: "fas fa-times text-danger" })]
                      )
                    ])
                  }
                },
                {
                  key: "buttons",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.showUrl
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-sm btn-secondary",
                              attrs: {
                                href: props.row.showUrl,
                                target: "_blank"
                              }
                            },
                            [_c("i", { staticClass: "fas fa-eye" })]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      props.row.editUrl
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.editModel(props.row.editUrl)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-pencil" })]
                          )
                        : _vm._e()
                    ])
                  }
                }
              ])
            })
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "nav nav-tabs mb-3" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link active",
            attrs: { "data-toggle": "tab", href: "#tabInfo" }
          },
          [_vm._v("Información")]
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { "data-toggle": "tab", href: "#tabDescription" }
          },
          [_vm._v("Descripción")]
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { "data-toggle": "tab", href: "#tabNotes" }
          },
          [_vm._v("Apuntes")]
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { "data-toggle": "tab", href: "#tabTasks" }
          },
          [_vm._v("Tareas")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-1" }, [
      _c("span", { staticClass: "btn btn-outline-secondary btn-file" }, [
        _c("span", { staticClass: "fileinput-new" }, [_vm._v("Buscar")]),
        _vm._v(" "),
        _c("span", { staticClass: "fileinput-exists" }, [_vm._v("Cambiar")]),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "file", name: "image", id: "image", accept: "image/*" }
        })
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-outline-secondary fileinput-exists",
          attrs: { href: "#", "data-dismiss": "fileinput" }
        },
        [_vm._v("Eliminar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Descripción")]),
        _vm._v(" "),
        _c("th", [_vm._v("Fecha")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _vm._v(
        "\n                    ¿Desea eliminar el grupo permanentemente?\n                "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }