var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.props.sortable
    ? _c("font-awesome-icon", {
        staticClass: "sort-icon",
        attrs: { icon: _vm.icon }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }