import EasyMDE from 'easymde';

export const crudMixin = {
    data() {
        return {
            loading: true,          // Loading inicial de la página
            fetchedData: null,      // Contenido inicial de la página
            alerts: [],             // Alerts con los mensajes de los formularios
            fetchParams: null,      // Parámetros de la URL desde la que se obtiene la carga inicial
            formObject: {},         // Objeto que contiene la información del formulario
            formLoading: false,     // Loading del formModal
            formSending: false,     // Loading en el botón de guardar
            formDeleting: false,    // Loading en el botón de eliminar
            formErrors: {           // Errores del formulario
                message: null,
                errors: []
            },
        }
    },

    watch: {
        alerts(newAlerts, oldAlerts) {
            // Cuando se agrega un alert
            // https://github.com/vuejs/vue/issues/2164#issuecomment-287022802
            if (newAlerts.length > oldAlerts.length) {
                // Último alert
                let lastAlert = _.last(newAlerts);

                // Show con delay para que se vea la animación
                if (typeof lastAlert.show == 'undefined') {
                    setTimeout(() => {
                        this.$set(lastAlert, 'show', true);
                    }, 150);
                }

                // Autohide
                if (lastAlert.autohide) {
                    setTimeout(() => {
                        this.closeAlert(lastAlert);
                    }, 6000);
                }
            }
        },

        'formObject.route': function (value) {
            if (value) {
                this.$nextTick(() => {
                    $('#formModal').trigger('loaded.bs.modal');
                });
            }
        },
    },

    created() {
        // Trae la información inicial
        this.fetchData(this.fetchUrl, this.fetchParams);
    },

    mounted() {
        let vue = this;

        // Cuando se abre el modal
        $('#formModal').on('loaded.bs.modal', function () {
            // Autofocus en los campos del modal
            $(this).find('[autofocus]').trigger('focus');

            let $modal = $(this);
            // Markdown
            $('.markdown').each(function () {
                let easymde = new EasyMDE({
                    element: this,
                    spellChecker: false,
                    forceSync: true,
                });

                // Si está dentro de un tab
                let $parent = $(this).closest('.tab-pane');
                if ($parent.length) {
                    // Actualiza el markdown cuando se muestra el tab en el que se encuentra
                    $('a[data-toggle="tab"][href="#' + $parent[0].id + '"]').on('shown.bs.tab.custom', function () {
                        easymde.codemirror.refresh();
                    });
                    // Apaga los eventos
                    $modal.on('hidden.bs.modal', function () {
                        $('a[data-toggle="tab"][href="#' + $parent[0].id + '"]').off('shown.bs.tab.custom');
                    });
                }
            });

            // Fileinput max size
            $('.fileinput', this).on('max_size.bs.fileinput', (e) => {
                let maxSize = vue.humanFileSize($(e.target).data('maxSize') * 1000000);
                let key = $(e.target).data('errorKey');

                if (key) {
                    let message = 'El peso máximo permitido es de ' + maxSize;
                    if (vue.formErrors.errors[key]) {
                        vue.formErrors.errors[key] = [message];
                    } else {
                        vue.$set(vue.formErrors.errors, key, [message]);
                    }
                }
            });
        }).on('shown.bs.modal', function () {
            // Autofocus en los campos del modal
            $(this).find('[autofocus]').trigger('focus');
        });

        // Borra los campos del modal al cerrarlo
        $('.form-modal').on('hidden.bs.modal', () => {
            this.formObject = {};
            this.formSending = false;
            this.formDeleting = false;
            this.formErrors = {message: null, errors: []};
        });

        // Multiple modals
        $('.modal').on('show.bs.modal', function () {
            let $modal = $(this);
            let baseZIndex = 1050;
            let modalZIndex = baseZIndex + ($('.modal.show').length * 20);
            let backdropZIndex = modalZIndex - 10;
            $modal.css('z-index', modalZIndex).css('overflow', 'auto');
            $('.modal-backdrop.show:last').css('z-index', backdropZIndex);
        });
        $('.modal').on('shown.bs.modal', function () {
            let baseBackdropZIndex = 1040;
            $('.modal-backdrop.show').each(function (i) {
                $(this).css('z-index', baseBackdropZIndex + (i * 20));
            });
        });
        $('.modal').on('hide.bs.modal', function () {
            let $modal = $(this);
            $modal.css('z-index', '');
        });
    },

    methods: {
        // Trae la data de la DB
        fetchData(url, params = null) {
            this.loading = true;
            axios.get(url, params).then((response) => {
                this.fetchedData = response.data;
                this.loading = false;

                // Si se paso un elemento a editar lo abre
                if (this.edit) {
                    let item = _.find(this.fetchedData.items, ['fakeId', parseInt(this.edit)]);
                    if (item) {
                        this.editModel(item.editUrl);
                    }
                }
            });
        },

        // Abre el dialog para editar o crear un modelo
        editModel(url) {
            this.formLoading = true;
            $('#formModal').modal('show');

            axios.get(url).then((response) => {
                // Carga los campos
                this.formObject = response.data;
            }).catch((error) => {
                // Cierra el modal
                $('#formModal').modal('hide');

                this.formErrors.errors = [];
                this.formErrors.message = null;

                // Recorre los errores para determinar si se
                // muestran los individuales o el general.
                if (error.response.data.errors) {
                    this.formErrors.errors = error.response.data.errors;
                } else if (error.response.data.message) {
                    this.formErrors.message = error.response.data.message;
                } else if (typeof error.response.data == 'string') {
                    this.formErrors.message = error.response.data;
                } else {
                    this.formErrors.message = error.response.statusText;
                }
            }).finally(() => {
                this.formLoading = false;
            });
        },

        // Guarda, o elimina, un modelo por AJAX
        updateModel(form, destroy = false) {
            // Button loading
            if (destroy) {
                $('#confirmationModal').modal('hide');
                this.formDeleting = true;
            } else {
                this.formSending = true;
            }

            // Form data
            let formData = new FormData(form);

            // Method
            let method = destroy ? 'delete' : 'post';

            // AJAX
            axios({
                method: method,
                url: form.action,
                data: formData,
            }).then((response) => {
                $(form).closest('.modal').modal('hide');
                this.fetchedData = response.data.data;
                let alert = {
                    message: response.data.message,
                    type: 'success',
                    autohide: true,
                }
                let copyAlerts = this.alerts.slice(0);
                copyAlerts.push(alert);
                this.alerts = copyAlerts;
            }).catch((error) => {
                this.formSending = false;
                this.formErrors.errors = [];
                this.formErrors.message = null;

                // Recorre los errores para determinar si se
                // muestran los individuales o el general.
                if (error.response.data.errors) {
                    this.formErrors.errors = error.response.data.errors;
                } else if (error.response.data.message) {
                    this.formErrors.message = error.response.data.message;
                } else if (typeof error.response.data == 'string') {
                    this.formErrors.message = error.response.data;
                } else {
                    this.formErrors.message = error.response.statusText;
                }
            });
        },

        // Cierra un alert
        closeAlert(alert) {
            // Oculta el alert
            alert.show = false;

            // Elimina el alert del array cuando se termina de ocultar
            setTimeout(() => {
                let copyAlerts = this.alerts.slice(0);
                copyAlerts.splice(copyAlerts.indexOf(alert), 1);
                this.alerts = copyAlerts;
            }, 150);
        },

        // Search table
        deburr(value, search) {
            return value != null &&
                search != null &&
                typeof value === 'string' &&
                _.deburr(value.toString()).toLowerCase().indexOf(_.deburr(search).toLowerCase()) !== -1;
        },

        // Devuelve los errores de un array devuelto por Laravel
        getArrayErrors(errors, inputKey) {
            let messages = [];
            // Recorre todas las entradas de errores y revisa si
            // no hay alguna que empiece con la key del input
            _.forEach(Object.keys(errors), (key) => {
                if (_.startsWith(key, inputKey + '.') || key == inputKey) {
                    _.forEach(errors[key],(value) => {
                        messages.push(value);
                    });
                }
            });

            return messages;
        },

        /**
         * Devuelve el tamaño de un archivo.
         *
         * @param {number} bytes Peso del archivo.
         * @param {boolean} si
         * @return {string}
         */
        humanFileSize(bytes, si = true) {
            let u, b = bytes, t = si ? 1000 : 1024;
            ['', si ? 'k' : 'K', ...'MGTPEZY'].find(x => (u = x, b /= t, b ** 2 < 1));
            return `${u ? (t * b).toFixed(1) : bytes} ${u}${!si && u ? 'i' : ''}B`;
        },
    }
}
