<template>
    <div class="container">
        <div class="btn-toolbar">
            <div class="btn-group btn-group-sm ml-auto">
                <button @click="editModel(createUrl)" type="button" class="btn btn-primary">CREAR GRUPO</button>
            </div>
        </div>
        <div class="alert-snackbar-container">
            <div v-for="alert in alerts" :class="'alert alert-dismissible alert-snackbar fade alert-' + alert.type + (alert.show ? ' show' : '')" role="alert">
                {{ alert.message }}
                <button @click="closeAlert(alert)" class="close" type="button" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="modal fade form-modal" id="formModal" data-backdrop="static" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div v-if="! formObject.route">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div :class="'alert alert-danger fade' + (formErrors.message ? ' show' : '')">{{ formErrors.message }}</div>
                            <div v-if="formLoading" class="d-flex justify-content-center p-4">
                                <div class="spinner-border"></div>
                            </div>
                        </div>
                    </div>
                    <form
                        v-else
                        @submit.prevent="updateModel($event.target)"
                        :action="formObject.route"
                        method="post"
                        accept-charset="UTF-8"
                        ref="form"
                        files="true"
                    >
                        <input type="hidden" name="_method" v-model="formObject.method">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formObject.title }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div :class="'alert alert-danger fade' + (formErrors.message ? ' show' : '')">{{ formErrors.message }}</div>
                            <ul class="nav nav-tabs mb-3">
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" href="#tabInfo">Información</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#tabDescription">Descripción</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#tabNotes">Apuntes</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#tabTasks">Tareas</a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane active" id="tabInfo">
                                    <div class="form-row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label for="title">Título</label>
                                                <input v-model="formObject.group.title" :class="'form-control' + (formErrors.errors.title ? ' is-invalid' : '')" id="title" name="title" type="text" autofocus required>
                                                <div v-for="error in formErrors.errors.title" class="invalid-feedback">
                                                    {{ error }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-3">
                                            <div class="form-group">
                                                <label for="year">Año</label>
                                                <input v-model="formObject.group.year" :class="'form-control' + (formErrors.errors.year ? ' is-invalid' : '')" id="year" name="year" type="text">
                                                <div v-for="error in formErrors.errors.year" class="invalid-feedback">
                                                    {{ error }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-3">
                                            <div class="form-group">
                                                <label for="current">Grupo actual</label>
                                                <select v-model="formObject.group.current" :class="'custom-select' + (formErrors.errors.current ? ' is-invalid' : '')" name="current" id="current" required>
                                                    <option value="0">No</option>
                                                    <option value="1">Sí</option>
                                                </select>
                                                <div v-for="error in formErrors.errors.current" class="invalid-feedback">
                                                    {{ error }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12 col-md-6">
                                            <label for="group_type_id">Tipo de grupo</label>
                                            <select v-model="formObject.group.group_type_id" :class="'custom-select' + (formErrors.errors.group_type_id ? ' is-invalid' : '')" name="group_type_id" id="group_type_id" required>
                                                <option v-for="groupType in formObject.groupTypes" :value="groupType.id">{{ groupType.name }}</option>
                                            </select>
                                            <div v-for="error in formErrors.errors.group_type_id" class="invalid-feedback">
                                                {{ error }}
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label for="published">Publicado</label>
                                                <select v-model="formObject.group.published" :class="'custom-select' + (formErrors.errors.published ? ' is-invalid' : '')" name="published" id="published" required>
                                                    <option value="1">Sí</option>
                                                    <option value="0">No</option>
                                                </select>
                                                <div v-for="error in formErrors.errors.published" class="invalid-feedback">
                                                    {{ error }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label for="schedule">Horarios</label>
                                                <input v-model="formObject.group.schedule" class="form-control" id="schedule" name="schedule" type="text">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label for="place">Lugar</label>
                                                <input v-model="formObject.group.place" class="form-control" id="place" name="place" type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <div :class="mainFilesIsInvalid">
                                                    <label for="mainFiles[]">Textos principales (los que se leyeron)</label>
                                                    <multiselect
                                                        v-model="formObject.selectedMainFiles"
                                                        :internal-search="false"
                                                        @search-change="mainFilesSearch"
                                                        @select="removeSecondaryItem"
                                                        @remove="addSecondaryItem"
                                                        :options="filteredMainFilesSorted"
                                                        :multiple="true"
                                                        :close-on-select="false"
                                                        :hide-selected="true"
                                                        placeholder=""
                                                        selectLabel=""
                                                        track-by="id"
                                                        label="fullTitle"
                                                    >
                                                    </multiselect>
                                                    <input v-for="file in formObject.selectedMainFiles" :value="file.id" name="mainFiles[]" type="hidden">
                                                </div>
                                                <div v-for="error in getArrayErrors(formErrors.errors, 'mainFiles')" class="invalid-feedback">
                                                    {{ error }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <div :class="secondaryFilesIsInvalid">
                                                    <label for="secondaryFiles[]">Textos secundarios</label>
                                                    <multiselect
                                                        v-model="formObject.selectedSecondaryFiles"
                                                        :internal-search="false"
                                                        @search-change="secondaryFilesSearch"
                                                        @select="removeMainItem"
                                                        @remove="addMainItem"
                                                        :options="filteredSecondaryFilesSorted"
                                                        :multiple="true"
                                                        :close-on-select="false"
                                                        :hide-selected="true"
                                                        placeholder=""
                                                        selectLabel=""
                                                        track-by="id"
                                                        label="fullTitle"
                                                    >
                                                    </multiselect>
                                                    <input v-for="file in formObject.selectedSecondaryFiles" :value="file.id" name="secondaryFiles[]" type="hidden">
                                                </div>
                                                <div v-for="error in getArrayErrors(formErrors.errors, 'secondaryFiles')" class="invalid-feedback">
                                                    {{ error }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label :class="'d-block' + (formErrors.errors.image ? ' is-invalid' : '')" for="image">
                                                    Imagen
                                                    <small class="text-muted">(máximo 600kB)</small>
                                                </label>
                                                <div v-for="error in formErrors.errors.image" class="invalid-feedback">
                                                    {{ error }}
                                                </div>
                                                <div :class="'fileinput fileinput-' + (formObject.group.image ? 'exists' : 'new')" data-provides="fileinput" data-error-key="image" data-max-size="0.6">
                                                    <div class="fileinput-preview img-thumbnail" data-trigger="fileinput" style="width: 200px; height: 150px;">
                                                        <img v-if="formObject.group.image" :src="formObject.group.imageUrl" class="img-fluid" alt="Imagen del grupo">
                                                    </div>
                                                    <div class="mt-1">
                                                        <span class="btn btn-outline-secondary btn-file">
                                                            <span class="fileinput-new">Buscar</span>
                                                            <span class="fileinput-exists">Cambiar</span>
                                                            <input type="file" name="image" id="image" accept="image/*">
                                                        </span>
                                                        <a href="#" class="btn btn-outline-secondary fileinput-exists" data-dismiss="fileinput">Eliminar</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tabDescription">
                                    <textarea v-model="formObject.group.description" class="form-control markdown" id="description" name="description" rows="10"></textarea>
                                </div>
                                <div class="tab-pane" id="tabNotes">
                                    <textarea v-model="formObject.group.notes" class="form-control markdown" id="notes" name="notes" rows="10"></textarea>
                                </div>
                                <div class="tab-pane" id="tabTasks">
                                    <div class="btn-toolbar mb-3">
                                        <div class="btn-group btn-group-sm ml-auto">
                                            <button type="button" class="btn btn-secondary" @click="addTask">
                                                <i class="fas fa-plus"></i>
                                                Agregar tarea
                                            </button>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-sm table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Descripción</th>
                                                    <th>Fecha</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(task, index) in formObject.group.tasks">
                                                    <td>
                                                        <textarea v-model="task.description" :name="'tasks[' + index + '][description]'" class="form-control" rows="1" required></textarea>
                                                        <input v-model="task.id" :name="'tasks[' + index + '][id]'" type="hidden">
                                                    </td>
                                                    <td>
                                                        <flat-pickr
                                                            v-model="task.dateDmy"
                                                            :config="flatPickrCfg"
                                                            :name="'tasks[' + index + '][dateDmy]'"
                                                            class="form-control"
                                                        ></flat-pickr>
                                                    </td>
                                                    <td class="table-btns">
                                                        <button @click="removeTask(index)" class="btn btn-sm btn-danger" type="button">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-for="error in getArrayErrors(formErrors.errors, 'tasks')" class="invalid-feedback d-block">
                                        {{ error }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                v-if="formObject.group.id"
                                :disabled="formDeleting"
                                class="btn btn-sm btn-outline-danger mr-auto"
                                data-toggle="modal"
                                data-target="#confirmationModal"
                                type="button"
                            >
                                <span v-if="! formDeleting">ELIMINAR</span>
                                <span v-else class="spinner-border spinner-border-sm"></span>
                            </button>
                            <button class="btn btn-sm btn-secondary" data-dismiss="modal" type="button">Cancelar</button>
                            <button :disabled="formSending" type="submit" class="btn btn-sm btn-primary">
                                <span v-if="! formSending">GUARDAR</span>
                                <span v-else class="spinner-border spinner-border-sm"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal fade" id="confirmationModal" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        ¿Desea eliminar el grupo permanentemente?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button
                            @click="updateModel($refs.form, true)"
                            class="btn btn-sm btn-danger"
                            data-dismiss="modal"
                            type="button"
                        >
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="d-flex justify-content-center">
            <div class="spinner-border"></div>
        </div>
        <div v-else class="pt-4">
            <v-client-table :data="fetchedData.items" :columns="fetchedData.columns" :options="fetchedData.options">
                <div slot="notes" slot-scope="props">
                    <span v-show="props.row.notes">
                        <i class="fas fa-check text-success"></i>
                    </span>
                    <span v-show="! props.row.notes">
                        <i class="fas fa-times text-danger"></i>
                    </span>
                </div>
                <div slot="image" slot-scope="props">
                    <span v-show="props.row.image">
                        <i class="fas fa-check text-success"></i>
                    </span>
                    <span v-show="! props.row.image">
                        <i class="fas fa-times text-danger"></i>
                    </span>
                </div>
                <div slot="current" slot-scope="props">
                    <span v-show="props.row.current">
                        <i class="fas fa-check text-success"></i>
                    </span>
                    <span v-show="! props.row.current">
                        <i class="fas fa-times text-danger"></i>
                    </span>
                </div>
                <div slot="published" slot-scope="props">
                    <span v-show="props.row.published">
                        <i class="fas fa-check text-success"></i>
                    </span>
                    <span v-show="! props.row.published">
                        <i class="fas fa-times text-danger"></i>
                    </span>
                </div>
                <div slot="buttons" slot-scope="props">
                    <a v-if="props.row.showUrl" class="btn btn-sm btn-secondary" :href="props.row.showUrl" target="_blank">
                        <i class="fas fa-eye"></i>
                    </a>
                    <button v-if="props.row.editUrl" @click="editModel(props.row.editUrl)" class="btn btn-sm btn-primary" type="button">
                        <i class="fas fa-pencil"></i>
                    </button>
                </div>
            </v-client-table>
        </div>
    </div>
</template>

<script>
    require('jasny-bootstrap/js/fileinput');
    import { crudMixin } from '../mixins/crudMixin.js';
    import Multiselect from 'vue-multiselect';
    import flatPickr from 'vue-flatpickr-component';
    import { Spanish } from 'flatpickr/dist/l10n/es.js';
    import 'flatpickr/dist/flatpickr.css';

    export default {
        mixins: [crudMixin],

        components: {
            Multiselect,
            flatPickr,
        },

        props: {
            // URL desde la que se trae el listado inicial
            fetchUrl: {
                type: String,
                required: true,
            },

            // URL desde la que se trae el formulario de creación
            createUrl: {
                type: String,
                required: true,
            },

            // ID del elemento a editar (al cargar la página)
            edit: {
                type: String,
                required: false,
                default: null,
            },
        },

        data() {
            return {
                flatPickrCfg: {
                    dateFormat: 'd/m/Y',
                    locale: Spanish,
                },
            }
        },

        computed: {
            // Listado de archivos ordenados alfabéticamente
            filteredMainFilesSorted() {
                return _.sortBy(this.formObject.filteredMainFiles, 'fullTitle');
            },
            filteredSecondaryFilesSorted() {
                return _.sortBy(this.formObject.filteredSecondaryFiles, 'fullTitle');
            },

            // Si hay un error en el multiselect
            mainFilesIsInvalid() {
                let hasErrors = _.find(this.formErrors.errors, (val, key) => _.startsWith(key, 'mainFiles'));

                return hasErrors ? 'is-invalid' : '';
            },
            secondaryFilesIsInvalid() {
                let hasErrors = _.find(this.formErrors.errors, (val, key) => _.startsWith(key, 'secondaryFiles'));

                return hasErrors ? 'is-invalid' : '';
            },
        },

        methods: {
            // Búsqueda del multiselect que ignora los acentos
            mainFilesSearch(search) {
                this.selectSearch(search, 'filteredMainFiles', 'mainFiles');
            },
            secondaryFilesSearch(search) {
                this.selectSearch(search, 'filteredSecondaryFiles', 'secondaryFiles');
            },
            selectSearch(search, filteredName, filesName) {
                if (search) {
                    this.formObject[filteredName] = this.formObject[filesName].filter(option => {
                        return _.deburr(option.fullTitle).toLowerCase().indexOf(_.deburr(search).toLowerCase()) !== -1;
                    });
                } else {
                    this.formObject[filteredName] = this.formObject[filesName];
                }
            },

            // Al seleccionar un elemento de un select lo saca del otro
            removeMainItem(option) {
                this.removeItem(option, this.formObject.mainFiles, this.formObject.filteredMainFiles);
            },
            removeSecondaryItem(option) {
                this.removeItem(option, this.formObject.secondaryFiles, this.formObject.filteredSecondaryFiles);
            },
            removeItem(option, array, filteredArray) {
                let index = _.findIndex(array, {'id': option.id});
                if (index != -1) {
                    array.splice(index, 1);
                    filteredArray.splice(index, 1);
                }
            },

            // Al borrar un elemento de un select lo devuelve al otro
            addMainItem(option) {
                this.addItem(option, this.formObject.mainFiles, this.formObject.filteredMainFiles);
            },
            addSecondaryItem(option) {
                this.addItem(option, this.formObject.secondaryFiles, this.formObject.filteredSecondaryFiles);
            },
            addItem(option, array, filteredArray) {
                array.push(option);
                filteredArray.push(option);
            },

            // Agrega una tarea
            addTask() {
                this.formObject.group.tasks.push({
                    id: null,
                    dateDmy: null,
                    description: null,
                });
            },

            // Elimina una tarea
            removeTask(index) {
                this.formObject.group.tasks.splice(index, 1);
            },
        }
    }
</script>

<style lang="scss">
    .is-invalid {
        .multiselect__tags {
            border-color: #e3342f;
        }
    }
</style>
