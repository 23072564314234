<template>
    <div class="container">
        <div class="btn-toolbar">
            <div class="btn-group btn-group-sm ml-auto">
                <button @click="importLinksModal" type="button" class="btn btn-primary">IMPORTAR LINKS</button>
            </div>
            <div class="btn-group btn-group-sm ml-1">
                <button @click="editModel(createUrl + '?type=Libro')" type="button" class="btn btn-primary">SUBIR ARCHIVO</button>
            </div>
            <div class="btn-group btn-group-sm ml-1">
                <button @click="editModel(createUrl + '?type=Traducción')" type="button" class="btn btn-primary">CREAR TEXTO</button>
            </div>
        </div>
        <div class="alert-snackbar-container">
            <div v-for="alert in alerts" :class="'alert alert-dismissible alert-snackbar fade alert-' + alert.type + (alert.show ? ' show' : '')" role="alert">
                {{ alert.message }}
                <button @click="closeAlert(alert)" class="close" type="button" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="modal fade form-modal" id="importModal" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <form
                        @submit.prevent="updateModel($event.target)"
                        :action="fetchUrl"
                        method="post"
                        accept-charset="UTF-8"
                        ref="formImport"
                    >
                        <input type="hidden" name="actionType" value="importLinks">
                        <div class="modal-header">
                            <h5 class="modal-title">Importar links</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div :class="'alert alert-danger fade' + (formErrors.message ? ' show' : '')">{{ formErrors.message }}</div>
                            <p>Importación de artículos y libros. Para subir varios formatos de un mismo libro, utilizar "subir archivo".</p>
                            <div class="btn-toolbar mb-3">
                                <div class="btn-group btn-group-sm ml-auto">
                                    <button type="button" class="btn btn-secondary" @click="addImportLink">
                                        <i class="fas fa-plus"></i>
                                        Agregar archivo
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-sm table-hover">
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            <th>Autor</th>
                                            <th>Título</th>
                                            <th>URL</th>
                                            <th>Publicado</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(file, index) in importLinks">
                                            <td>
                                                <select v-model="file.file_type_id" :name="'files[' + index + '][file_type_id]'" class="custom-select">
                                                    <option v-for="(value, id) in fileTypes" :value="id">{{ value }}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input v-model="file.author" :name="'files[' + index + '][author]'" class="form-control" type="text">
                                            </td>
                                            <td>
                                                <input v-model="file.title" :name="'files[' + index + '][title]'" class="form-control" type="text" required>
                                            </td>
                                            <td>
                                                <input v-model="file.url" :name="'files[' + index + '][url]'" class="form-control" type="text" required>
                                            </td>
                                            <td>
                                                <select v-model="file.published" :name="'files[' + index + '][published]'" class="custom-select">
                                                    <option value="1">Sí</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </td>
                                            <td class="table-btns">
                                                <button @click="removeImportLink(index)" class="btn btn-sm btn-danger" type="button">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-sm btn-secondary" data-dismiss="modal" type="button">Cancelar</button>
                            <button :disabled="formSending" type="submit" class="btn btn-sm btn-primary">
                                <span v-if="! formSending">GUARDAR</span>
                                <span v-else class="spinner-border spinner-border-sm"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal fade form-modal" id="formModal" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div v-if="! formObject.route">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div :class="'alert alert-danger fade' + (formErrors.message ? ' show' : '')">{{ formErrors.message }}</div>
                            <div v-if="formLoading" class="d-flex justify-content-center p-4">
                                <div class="spinner-border"></div>
                            </div>
                        </div>
                    </div>
                    <form
                        v-else
                        @submit.prevent="updateModel($event.target)"
                        :action="formObject.route"
                        method="post"
                        accept-charset="UTF-8"
                        ref="form"
                        files="true"
                    >
                        <input type="hidden" name="_method" v-model="formObject.method">
                        <div class="modal-header">
                            <h5 class="modal-title">Nuevo {{ formObject.type == 'Libro' ? 'libro' : 'texto'}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div :class="'alert alert-danger fade' + (formErrors.message ? ' show' : '')">{{ formErrors.message }}</div>
                            <div class="form-row">
                                <div class="col-12 col-md-5">
                                    <div class="form-group">
                                        <label for="author">Autor</label>
                                        <input v-model="formObject.file.author" class="form-control" id="author" name="author" type="text" autofocus>
                                    </div>
                                </div>
                                <div class="col-12 col-md-5">
                                    <div class="form-group">
                                        <label for="title">Título</label>
                                        <input v-model="formObject.file.title" :class="'form-control' + (formErrors.errors.title ? ' is-invalid' : '')" id="title" name="title" type="text" required>
                                        <div v-for="error in formErrors.errors.title" class="invalid-feedback">
                                            {{ error }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="form-group">
                                        <label for="published">Publicado</label>
                                        <select v-model="formObject.file.published" :class="'custom-select' + (formErrors.errors.published ? ' is-invalid' : '')" name="published" id="published" required>
                                            <option value="1">Sí</option>
                                            <option value="0">No</option>
                                        </select>
                                        <div v-for="error in formErrors.errors.published" class="invalid-feedback">
                                            {{ error }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12 col-md-5">
                                    <div class="form-group">
                                        <label for="file_type_id">Tipo de archivo</label>
                                        <select v-model="formObject.file.file_type_id" :class="'custom-select' + (formErrors.errors.file_type_id ? ' is-invalid' : '')" name="file_type_id" id="file_type_id" required>
                                            <option v-for="(value, id) in formObject.fileTypes" :value="id">{{ value }}</option>
                                        </select>
                                        <div v-for="error in formErrors.errors.file_type_id" class="invalid-feedback">
                                            {{ error }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="['Texto prestado', 'Texto propio', 'Traducción'].includes(formObject.type)">
                                <div class="form-row">
                                    <div class="col-12">
                                        <label for="text">Texto</label>
                                        <textarea v-model="formObject.file.text" class="form-control markdown" id="text" name="text" rows="10"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <p v-if="formObject.fileTypes[formObject.file.file_type_id] == 'Libro'">Agregar de un mismo libro los distintos formatos (epub, mobi, etc).</p>
                                <p v-else>Se puede subir solo un archivo por artículo. Si se quieren subir varios se puede utilizar "importar links".</p>
                                <p>Se carga el archivo o la URL.</p>
                                <div class="btn-toolbar mb-3">
                                    <div class="btn-group btn-group-sm ml-auto">
                                        <button type="button" class="btn btn-secondary" @click="addFileRow">
                                            <i class="fas fa-plus"></i>
                                            Agregar archivo
                                        </button>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-sm table-hover">
                                        <thead>
                                            <tr>
                                                <th>Archivo</th>
                                                <th>URL</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(file, index) in formObject.file.files" :key="file.fakeId">
                                                <td>
                                                    <div v-if="file.fileName">
                                                        <input v-if="file.fileName" :value="file.originalName" class="form-control" type="text" readonly>
                                                        <input v-if="file.fileName" :name="'files[' + index + '][fileName]'" :value="file.fileName" type="hidden">
                                                    </div>
                                                    <div v-else class="fileinput input-group fileinput-new" data-provides="fileinput">
                                                        <div class="form-control" data-trigger="fileinput">
                                                            <span class="fileinput-filename"></span>
                                                        </div>
                                                        <span class="input-group-append">
                                                            <span class="input-group-text fileinput-exists" data-dismiss="fileinput">Eliminar</span>
                                                            <span class="input-group-text btn btn-file">
                                                                <span class="fileinput-new">Buscar</span>
                                                                <span class="fileinput-exists">Cambiar</span>
                                                                <input :name="'files[' + index + '][fileName]'" type="file">
                                                            </span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input v-model="file.url" :name="'files[' + index + '][url]'" class="form-control" :disabled="file.fileName" type="text">
                                                </td>
                                                <td class="table-btns">
                                                    <button @click="removeFileRow(index)" class="btn btn-sm btn-danger" type="button">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-for="error in getArrayErrors(formErrors.errors, 'files')" class="invalid-feedback d-block">
                                    {{ error }}
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                v-if="formObject.file.id"
                                :disabled="formDeleting"
                                class="btn btn-sm btn-outline-danger mr-auto"
                                data-toggle="modal"
                                data-target="#confirmationModal"
                                type="button"
                            >
                                <span v-if="! formDeleting">ELIMINAR</span>
                                <span v-else class="spinner-border spinner-border-sm"></span>
                            </button>
                            <button class="btn btn-sm btn-secondary" data-dismiss="modal" type="button">Cancelar</button>
                            <button :disabled="formSending" type="submit" class="btn btn-sm btn-primary">
                                <span v-if="! formSending">GUARDAR</span>
                                <span v-else class="spinner-border spinner-border-sm"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal fade" id="confirmationModal" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        ¿Desea eliminar el archivo permanentemente?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button
                            @click="updateModel($refs.form, true)"
                            class="btn btn-sm btn-danger"
                            data-dismiss="modal"
                            type="button"
                        >
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="d-flex justify-content-center">
            <div class="spinner-border"></div>
        </div>
        <div v-else class="pt-4">
            <v-client-table :data="fetchedData.items" :columns="fetchedData.columns" :options="fetchedData.options">
                <div slot="published" slot-scope="props">
                    <span v-show="props.row.published">
                        <i class="fas fa-check text-success"></i>
                    </span>
                    <span v-show="! props.row.published">
                        <i class="fas fa-times text-danger"></i>
                    </span>
                </div>
                <div slot="buttons" slot-scope="props">
                    <div v-if="props.row.showUrls.length > 1" class="btn-group">
                        <button class="btn btn-sm btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
                            <i class="fas fa-download"></i>
                        </button>
                        <div class="dropdown-menu">
                            <a v-for="showUrl in props.row.showUrls" :href="showUrl.url" class="dropdown-item" target="_blank">{{ showUrl.text }}</a>
                        </div>
                    </div>
                    <a v-else :href="props.row.showUrls[0]['url']" class="btn btn-sm btn-secondary" target="_blank">
                        <i class="fas fa-download"></i>
                    </a>
                    <button v-if="props.row.editUrl" @click="editModel(props.row.editUrl)" class="btn btn-sm btn-primary" type="button">
                        <i class="fas fa-pencil"></i>
                    </button>
                </div>
            </v-client-table>
        </div>
    </div>
</template>

<script>
    require('jasny-bootstrap/js/fileinput');
    import { crudMixin } from '../mixins/crudMixin.js';

    export default {
        mixins: [crudMixin],

        props: {
            // URL desde la que se trae el listado inicial
            fetchUrl: {
                type: String,
                required: true
            },

            // URL desde la que se trae el formulario de creación
            createUrl: {
                type: String,
                required: true,
            },

            // Tipos de archivos para los select
            fileTypes: {
                type: Object,
                required: true,
            },

            // ID del elemento a editar (al cargar la página)
            edit: {
                type: String,
                required: false,
                defautl: null,
            }
        },

        data() {
            return {
                importLinks: null,  // Items a importar mediante "importar links"
            }
        },

        mounted() {
            // Borra los campos del modal al cerrarlo
            $('#importModal').on('hidden.bs.modal', () => {
                this.importLinks = null;
            });
        },

        methods: {
            // Abre el dialog para importar links
            importLinksModal() {
                this.importLinks = [
                    {
                        file_type_id: Object.keys(this.fileTypes)[0],
                        author: null,
                        title: null,
                        url: null,
                        published: 1,
                    }
                ];
                $('#importModal').modal('show');
            },

            // Agrega una nueva fila para importar archivos
            addFileRow() {
                if (this.formObject.file.files == null) {
                    this.formObject.file.files = [];
                }
                const fakeId = this.getNewFakeId(this.formObject.file.files);
                this.formObject.file.files.push({
                    fakeId: fakeId,
                    file: null,
                    url: null,
                });
            },

            // Elimina una fila de archivos a importar
            removeFileRow(index) {
                this.formObject.file.files.splice(index, 1);
            },

            // Devuelve el ID más alto + 1
            getNewFakeId(rows) {
                let fakeId = 0;

                for (let row of rows) {
                    fakeId = (row.fakeId > fakeId) ? row.fakeId : fakeId;
                }

                return fakeId + 1;
            },

            // Agrega una nueva fila para importar archivos
            addImportLink() {
                this.importLinks.push({
                    file_type_id: Object.keys(this.fileTypes)[0],
                    author: null,
                    title: null,
                    url: null,
                    published: 1,
                });
            },

            // Elimina una fila de archivos a importar
            removeImportLink(index) {
                this.importLinks.splice(index, 1);
            },
        }
    }
</script>

<style lang="scss">
    .table {
        td {
            vertical-align: middle;
        }
        thead {
            th {
                position: relative;
            }
        }
        .sort-icon {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            height: 100%;
        }
    }

    .fileinput {
        margin-bottom: 0;
        &.input-group {
            display: flex;
        }
        .input-group-text:hover {
            background-color: #d9dcdf;
            cursor: pointer;
        }
        .fileinput-filename {
            padding-left: 10px;
        }
    }
</style>
