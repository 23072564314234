var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "btn-toolbar" }, [
      _c("div", { staticClass: "btn-group btn-group-sm ml-auto" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.importLinksModal }
          },
          [_vm._v("IMPORTAR LINKS")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "btn-group btn-group-sm ml-1" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.editModel(_vm.createUrl + "?type=Libro")
              }
            }
          },
          [_vm._v("SUBIR ARCHIVO")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "btn-group btn-group-sm ml-1" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.editModel(_vm.createUrl + "?type=Traducción")
              }
            }
          },
          [_vm._v("CREAR TEXTO")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "alert-snackbar-container" },
      _vm._l(_vm.alerts, function(alert) {
        return _c(
          "div",
          {
            class:
              "alert alert-dismissible alert-snackbar fade alert-" +
              alert.type +
              (alert.show ? " show" : ""),
            attrs: { role: "alert" }
          },
          [
            _vm._v("\n            " + _vm._s(alert.message) + "\n            "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button", "aria-label": "Close" },
                on: {
                  click: function($event) {
                    return _vm.closeAlert(alert)
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade form-modal",
        attrs: {
          id: "importModal",
          "data-backdrop": "static",
          tabindex: "-1",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-xl", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "form",
                {
                  ref: "formImport",
                  attrs: {
                    action: _vm.fetchUrl,
                    method: "post",
                    "accept-charset": "UTF-8"
                  },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.updateModel($event.target)
                    }
                  }
                },
                [
                  _c("input", {
                    attrs: {
                      type: "hidden",
                      name: "actionType",
                      value: "importLinks"
                    }
                  }),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "div",
                      {
                        class:
                          "alert alert-danger fade" +
                          (_vm.formErrors.message ? " show" : "")
                      },
                      [_vm._v(_vm._s(_vm.formErrors.message))]
                    ),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        'Importación de artículos y libros. Para subir varios formatos de un mismo libro, utilizar "subir archivo".'
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "btn-toolbar mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "btn-group btn-group-sm ml-auto" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: { click: _vm.addImportLink }
                            },
                            [
                              _c("i", { staticClass: "fas fa-plus" }),
                              _vm._v(
                                "\n                                    Agregar archivo\n                                "
                              )
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-responsive" }, [
                      _c(
                        "table",
                        { staticClass: "table table-sm table-hover" },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.importLinks, function(file, index) {
                              return _c("tr", [
                                _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: file.file_type_id,
                                          expression: "file.file_type_id"
                                        }
                                      ],
                                      staticClass: "custom-select",
                                      attrs: {
                                        name:
                                          "files[" + index + "][file_type_id]"
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            file,
                                            "file_type_id",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.fileTypes, function(value, id) {
                                      return _c(
                                        "option",
                                        { domProps: { value: id } },
                                        [_vm._v(_vm._s(value))]
                                      )
                                    }),
                                    0
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: file.author,
                                        expression: "file.author"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      name: "files[" + index + "][author]",
                                      type: "text"
                                    },
                                    domProps: { value: file.author },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          file,
                                          "author",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: file.title,
                                        expression: "file.title"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      name: "files[" + index + "][title]",
                                      type: "text",
                                      required: ""
                                    },
                                    domProps: { value: file.title },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          file,
                                          "title",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: file.url,
                                        expression: "file.url"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      name: "files[" + index + "][url]",
                                      type: "text",
                                      required: ""
                                    },
                                    domProps: { value: file.url },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          file,
                                          "url",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: file.published,
                                          expression: "file.published"
                                        }
                                      ],
                                      staticClass: "custom-select",
                                      attrs: {
                                        name: "files[" + index + "][published]"
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            file,
                                            "published",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "1" } }, [
                                        _vm._v("Sí")
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "0" } }, [
                                        _vm._v("No")
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-btns" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-danger",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeImportLink(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-trash-alt"
                                      })
                                    ]
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-secondary",
                        attrs: { "data-dismiss": "modal", type: "button" }
                      },
                      [_vm._v("Cancelar")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        attrs: { disabled: _vm.formSending, type: "submit" }
                      },
                      [
                        !_vm.formSending
                          ? _c("span", [_vm._v("GUARDAR")])
                          : _c("span", {
                              staticClass: "spinner-border spinner-border-sm"
                            })
                      ]
                    )
                  ])
                ]
              )
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade form-modal",
        attrs: {
          id: "formModal",
          "data-backdrop": "static",
          tabindex: "-1",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-xl", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              !_vm.formObject.route
                ? _c("div", [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "div",
                        {
                          class:
                            "alert alert-danger fade" +
                            (_vm.formErrors.message ? " show" : "")
                        },
                        [_vm._v(_vm._s(_vm.formErrors.message))]
                      ),
                      _vm._v(" "),
                      _vm.formLoading
                        ? _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-center p-4"
                            },
                            [_c("div", { staticClass: "spinner-border" })]
                          )
                        : _vm._e()
                    ])
                  ])
                : _c(
                    "form",
                    {
                      ref: "form",
                      attrs: {
                        action: _vm.formObject.route,
                        method: "post",
                        "accept-charset": "UTF-8",
                        files: "true"
                      },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.updateModel($event.target)
                        }
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formObject.method,
                            expression: "formObject.method"
                          }
                        ],
                        attrs: { type: "hidden", name: "_method" },
                        domProps: { value: _vm.formObject.method },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formObject,
                              "method",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-header" }, [
                        _c("h5", { staticClass: "modal-title" }, [
                          _vm._v(
                            "Nuevo " +
                              _vm._s(
                                _vm.formObject.type == "Libro"
                                  ? "libro"
                                  : "texto"
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(3)
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-body" }, [
                        _c(
                          "div",
                          {
                            class:
                              "alert alert-danger fade" +
                              (_vm.formErrors.message ? " show" : "")
                          },
                          [_vm._v(_vm._s(_vm.formErrors.message))]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-12 col-md-5" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "author" } }, [
                                _vm._v("Autor")
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formObject.file.author,
                                    expression: "formObject.file.author"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "author",
                                  name: "author",
                                  type: "text",
                                  autofocus: ""
                                },
                                domProps: { value: _vm.formObject.file.author },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formObject.file,
                                      "author",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-md-5" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "title" } }, [
                                  _vm._v("Título")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formObject.file.title,
                                      expression: "formObject.file.title"
                                    }
                                  ],
                                  class:
                                    "form-control" +
                                    (_vm.formErrors.errors.title
                                      ? " is-invalid"
                                      : ""),
                                  attrs: {
                                    id: "title",
                                    name: "title",
                                    type: "text",
                                    required: ""
                                  },
                                  domProps: {
                                    value: _vm.formObject.file.title
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.formObject.file,
                                        "title",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm._l(_vm.formErrors.errors.title, function(
                                  error
                                ) {
                                  return _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(error) +
                                          "\n                                    "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-md-2" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "published" } }, [
                                  _vm._v("Publicado")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.formObject.file.published,
                                        expression: "formObject.file.published"
                                      }
                                    ],
                                    class:
                                      "custom-select" +
                                      (_vm.formErrors.errors.published
                                        ? " is-invalid"
                                        : ""),
                                    attrs: {
                                      name: "published",
                                      id: "published",
                                      required: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.formObject.file,
                                          "published",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "1" } }, [
                                      _vm._v("Sí")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "0" } }, [
                                      _vm._v("No")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.formErrors.errors.published,
                                  function(error) {
                                    return _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(error) +
                                            "\n                                    "
                                        )
                                      ]
                                    )
                                  }
                                )
                              ],
                              2
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-12 col-md-5" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "file_type_id" } },
                                  [_vm._v("Tipo de archivo")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.formObject.file.file_type_id,
                                        expression:
                                          "formObject.file.file_type_id"
                                      }
                                    ],
                                    class:
                                      "custom-select" +
                                      (_vm.formErrors.errors.file_type_id
                                        ? " is-invalid"
                                        : ""),
                                    attrs: {
                                      name: "file_type_id",
                                      id: "file_type_id",
                                      required: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.formObject.file,
                                          "file_type_id",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  _vm._l(_vm.formObject.fileTypes, function(
                                    value,
                                    id
                                  ) {
                                    return _c(
                                      "option",
                                      { domProps: { value: id } },
                                      [_vm._v(_vm._s(value))]
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.formErrors.errors.file_type_id,
                                  function(error) {
                                    return _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(error) +
                                            "\n                                    "
                                        )
                                      ]
                                    )
                                  }
                                )
                              ],
                              2
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        [
                          "Texto prestado",
                          "Texto propio",
                          "Traducción"
                        ].includes(_vm.formObject.type)
                          ? _c("div", [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("label", { attrs: { for: "text" } }, [
                                    _vm._v("Texto")
                                  ]),
                                  _vm._v(" "),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.formObject.file.text,
                                        expression: "formObject.file.text"
                                      }
                                    ],
                                    staticClass: "form-control markdown",
                                    attrs: {
                                      id: "text",
                                      name: "text",
                                      rows: "10"
                                    },
                                    domProps: {
                                      value: _vm.formObject.file.text
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.formObject.file,
                                          "text",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          : _c(
                              "div",
                              [
                                _vm.formObject.fileTypes[
                                  _vm.formObject.file.file_type_id
                                ] == "Libro"
                                  ? _c("p", [
                                      _vm._v(
                                        "Agregar de un mismo libro los distintos formatos (epub, mobi, etc)."
                                      )
                                    ])
                                  : _c("p", [
                                      _vm._v(
                                        'Se puede subir solo un archivo por artículo. Si se quieren subir varios se puede utilizar "importar links".'
                                      )
                                    ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v("Se carga el archivo o la URL.")
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "btn-toolbar mb-3" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn-group btn-group-sm ml-auto"
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-secondary",
                                          attrs: { type: "button" },
                                          on: { click: _vm.addFileRow }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-plus"
                                          }),
                                          _vm._v(
                                            "\n                                        Agregar archivo\n                                    "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "table table-sm table-hover"
                                    },
                                    [
                                      _vm._m(4),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.formObject.file.files,
                                          function(file, index) {
                                            return _c(
                                              "tr",
                                              { key: file.fakeId },
                                              [
                                                _c("td", [
                                                  file.fileName
                                                    ? _c("div", [
                                                        file.fileName
                                                          ? _c("input", {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "text",
                                                                readonly: ""
                                                              },
                                                              domProps: {
                                                                value:
                                                                  file.originalName
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        file.fileName
                                                          ? _c("input", {
                                                              attrs: {
                                                                name:
                                                                  "files[" +
                                                                  index +
                                                                  "][fileName]",
                                                                type: "hidden"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  file.fileName
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ])
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "fileinput input-group fileinput-new",
                                                          attrs: {
                                                            "data-provides":
                                                              "fileinput"
                                                          }
                                                        },
                                                        [
                                                          _vm._m(5, true),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "input-group-append"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "input-group-text fileinput-exists",
                                                                  attrs: {
                                                                    "data-dismiss":
                                                                      "fileinput"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Eliminar"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "input-group-text btn btn-file"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "fileinput-new"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Buscar"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "fileinput-exists"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Cambiar"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("input", {
                                                                    attrs: {
                                                                      name:
                                                                        "files[" +
                                                                        index +
                                                                        "][fileName]",
                                                                      type:
                                                                        "file"
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: file.url,
                                                        expression: "file.url"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      name:
                                                        "files[" +
                                                        index +
                                                        "][url]",
                                                      disabled: file.fileName,
                                                      type: "text"
                                                    },
                                                    domProps: {
                                                      value: file.url
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          file,
                                                          "url",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "table-btns" },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-danger",
                                                        attrs: {
                                                          type: "button"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeFileRow(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-trash-alt"
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.getArrayErrors(
                                    _vm.formErrors.errors,
                                    "files"
                                  ),
                                  function(error) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "invalid-feedback d-block"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(error) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  }
                                )
                              ],
                              2
                            )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-footer" }, [
                        _vm.formObject.file.id
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-outline-danger mr-auto",
                                attrs: {
                                  disabled: _vm.formDeleting,
                                  "data-toggle": "modal",
                                  "data-target": "#confirmationModal",
                                  type: "button"
                                }
                              },
                              [
                                !_vm.formDeleting
                                  ? _c("span", [_vm._v("ELIMINAR")])
                                  : _c("span", {
                                      staticClass:
                                        "spinner-border spinner-border-sm"
                                    })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-secondary",
                            attrs: { "data-dismiss": "modal", type: "button" }
                          },
                          [_vm._v("Cancelar")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary",
                            attrs: { disabled: _vm.formSending, type: "submit" }
                          },
                          [
                            !_vm.formSending
                              ? _c("span", [_vm._v("GUARDAR")])
                              : _c("span", {
                                  staticClass:
                                    "spinner-border spinner-border-sm"
                                })
                          ]
                        )
                      ])
                    ]
                  )
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "confirmationModal",
          "data-backdrop": "static",
          tabindex: "-1",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(6),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" }
                  },
                  [_vm._v("Cancelar")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-danger",
                    attrs: { "data-dismiss": "modal", type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.updateModel(_vm.$refs.form, true)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        Aceptar\n                    "
                    )
                  ]
                )
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c("div", { staticClass: "spinner-border" })
        ])
      : _c(
          "div",
          { staticClass: "pt-4" },
          [
            _c("v-client-table", {
              attrs: {
                data: _vm.fetchedData.items,
                columns: _vm.fetchedData.columns,
                options: _vm.fetchedData.options
              },
              scopedSlots: _vm._u([
                {
                  key: "published",
                  fn: function(props) {
                    return _c("div", {}, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: props.row.published,
                              expression: "props.row.published"
                            }
                          ]
                        },
                        [_c("i", { staticClass: "fas fa-check text-success" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !props.row.published,
                              expression: "! props.row.published"
                            }
                          ]
                        },
                        [_c("i", { staticClass: "fas fa-times text-danger" })]
                      )
                    ])
                  }
                },
                {
                  key: "buttons",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.showUrls.length > 1
                        ? _c("div", { staticClass: "btn-group" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-secondary dropdown-toggle",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "dropdown"
                                }
                              },
                              [_c("i", { staticClass: "fas fa-download" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "dropdown-menu" },
                              _vm._l(props.row.showUrls, function(showUrl) {
                                return _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      href: showUrl.url,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(_vm._s(showUrl.text))]
                                )
                              }),
                              0
                            )
                          ])
                        : _c(
                            "a",
                            {
                              staticClass: "btn btn-sm btn-secondary",
                              attrs: {
                                href: props.row.showUrls[0]["url"],
                                target: "_blank"
                              }
                            },
                            [_c("i", { staticClass: "fas fa-download" })]
                          ),
                      _vm._v(" "),
                      props.row.editUrl
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.editModel(props.row.editUrl)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-pencil" })]
                          )
                        : _vm._e()
                    ])
                  }
                }
              ])
            })
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Importar links")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Tipo")]),
        _vm._v(" "),
        _c("th", [_vm._v("Autor")]),
        _vm._v(" "),
        _c("th", [_vm._v("Título")]),
        _vm._v(" "),
        _c("th", [_vm._v("URL")]),
        _vm._v(" "),
        _c("th", [_vm._v("Publicado")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Archivo")]),
        _vm._v(" "),
        _c("th", [_vm._v("URL")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form-control", attrs: { "data-trigger": "fileinput" } },
      [_c("span", { staticClass: "fileinput-filename" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _vm._v(
        "\n                    ¿Desea eliminar el archivo permanentemente?\n                "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }